import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    token: null,
    user: null,
    status: { waitCount: 0, notes: [] },
    isLogin: false,
    isAdmin: false,
    isShowSpinner: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
      state.isLogin = user != null
      state.isAdmin = user ? user.level >= 8 : false

      var showSkypeLogo = false
      if (user && user.recommendCode) {
        if (
          user.recommendCode == 'ca66' ||
          user.recommendCode == 'ca77' ||
          user.recommendCode == 'ca88' ||
          user.recommendCode == 'ca99'
        ) {
          showSkypeLogo = true
        }
      }

      for (var i = 0; i < 20; i++) {
        setTimeout(function() {
          if (showSkypeLogo) {
            document.body.lastElementChild.previousElementSibling.previousElementSibling.style.display = 'none'
            document.body.lastElementChild.previousElementSibling.style.display = 'none'
            document.getElementById('skype-logo').style.display = 'block'
          }
        }, i * 200)
      }
    },
    setStatus(state, status) {
      state.status = status
    },
    showSpinner(state, isShow) {
      state.isShowSpinner = isShow
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setStatus({ commit }, status) {
      commit('setStatus', status)
    },
    showSpinner({ commit }, isShow) {
      commit('showSpinner', isShow)
    },
  },
  getters: {
    getToken: function(state) {
      return state.token
    },
    getUser: function(state) {
      return state.user
    },
    getIsLogin: function(state) {
      return state.isLogin
    },
    getIsAdmin: function(state) {
      return state.isAdmin
    },
    getStatus: function(state) {
      return state.status
    },
  },
})
