import Api from '@/services/Api'

export default {
  register(credentials) {
    return Api().post('/api/auth/register', credentials)
  },
  login(credentials) {
    return Api().post('/api/auth/login', credentials)
  },
  getUser(token) {
    return Api(token).get('/api/auth/user')
  }
}
